<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>

		<loading :active.sync="isLoading"
		         :is-full-page="true"/>

		<side-menu v-if="!isLoading"
		           v-show="showMenu"
		           :id-museum="encrypted"
		           @close-menu="showMenu = false"/>

		<nav v-if="!isLoading">
			<router-link :to="{ name: 'Presentation', params: { idMuseum: encrypted } }" class="logo">
				<img class="logo-image" :src="museum.topBarLogoImageUrl ? museum.topBarLogoImageUrl : museum.logoImageUrl" alt="">
			</router-link>
			<div v-if="museum.menuAvailable" class="menu" @click="showMenu = true; setFullScreen()">
				<img class="menu-icon" src="@/assets/icons/stayrelax_black.png" alt=""> <!-- TODO change this icon -->
			</div>
			<router-link v-if="!museum.menuAvailable && sideMenu && sideMenu.downloadsTopBar" :to="{ name: 'Downloads', params: { idMuseum: encrypted } }" class="menu">
				<i class="mm-icon mm-icon-download menu-icon"/>
			</router-link>
			<router-link v-if="!museum.menuAvailable && sideMenu && sideMenu.languageTopBar" :to="{ name: 'Languages', params: { idMuseum: encrypted } }" class="menu">
				<i v-if="museum.code === 'MPICASSOM'" class="mm-icon icon-language-edited menu-icon"/>
				<i v-else class="mm-icon mm-icon-languages menu-icon"/>
			</router-link>
		</nav>

		<div v-if="!isLoading" class="options">

			<!-- Sponsors -->
			<div v-if="sectionSponsors && sectionSponsors.enableSection" class="option sponsors"
				:style="getNavigationSectionStyles('sectionSponsors')" @click="goToSponsors(sectionSponsors.resources)">
				<div class="title">
					<h2 class="sponsors">{{ sectionSponsors ? sectionSponsors.locale.title : $t('tour_title') }}</h2>
					<div class="arrow sponsors">
						<i class="mm-icon mm-icon-arrow-right big"/>
					</div>
				</div>
			</div>

			<!-- Keyboard -->
			<div v-if="sectionKeyboard && sectionKeyboard.enableSection" class="option keyboard"
				:style="getNavigationSectionStyles('sectionKeyboard')" @click="setFullScreen('keyboard')">
				<div class="title">
					<h2 class="keyboard" :style="fontMalaga">{{ sectionKeyboard ? sectionKeyboard.locale.title : $t('tour_title') }}</h2>
					<div class="arrow keyboard">
						<i class="mm-icon mm-icon-arrow-right big"/>
					</div>
				</div>
			</div>

			<!--
				FIXME
				 This is a special and temporal case, that obviously should not be done, but for a presentation in
			     CACSA we need the following order: Routes, Highlighted Routes, and Exhibitions
			     https://grupotks.atlassian.net/browse/MS-917
			 -->
			<template v-if="museum.code === 'MPICASSOM'">
				<!-- Routes -->
				<div v-if="routes && routes.length && sectionRoutes && sectionRoutes.enableSection" class="option routes"
					:style="getNavigationSectionStyles('sectionRoutes')" @click="setFullScreen('routes')">
					<div class="title">
						<h2 class="routes" :style="fontMalaga">{{ sectionRoutes ? sectionRoutes.locale.title : $t('selected_routes') }}</h2>
						<div class="arrow routes">
							<i class="mm-icon mm-icon-arrow-right big" />
						</div>
					</div>
				</div>

				<template v-for="(route) in highlightedRoutes">
					<!--	para que se muestre segun la imagen				-->
					<template v-if="!(route.mainImageUrl.includes('a46ea814b6f4ea846678119d51f3bd37c5d1de5f') || route.mainImageUrl.includes('16e8fab780d1db23d0632b6ad3d4f0c010120178'))">
						<div v-if="true" :key="route.idRoute + 133" class="option highlighted-route"
						:style="getHighlightedRouteSectionStyles(route)" @click="goToRoute(route)">

						<div class="title">
							<h2 class="highlighted-route" :style="fontMalaga">{{ route.locale.name }}</h2>
							<div class="arrow highlighted-route">
								<i class="mm-icon mm-icon-arrow-right big" />
							</div>
						</div>
					</div>
					</template>
					<div v-if="route.mainImageUrl.includes('a46ea814b6f4ea846678119d51f3bd37c5d1de5f') || route.mainImageUrl.includes('16e8fab780d1db23d0632b6ad3d4f0c010120178')" :key="route.idRoute + 133"
							 class="option highlighted-route"
							 :style="getHighlightedRouteSectionStyles(route)" @click="setFullScreen('keyboard')" >

						<div class="title">
							<h2 class="highlighted-route" :style="fontMalaga">{{ route.locale.name }}</h2>
							<div class="arrow highlighted-route">
								<i class="mm-icon mm-icon-arrow-right big" />
							</div>
						</div>
					</div>
					<!--	para que se muestre segun la imagen				-->

					<!-- Exhibitions -->
					<div v-if="exhibitions && exhibitions.length && sectionExhibitions && sectionExhibitions.enableSection"
						:key="route.idRoute + 2" class="option exhibitions"
						:style="getNavigationSectionStyles('sectionExhibitions')" @click="setFullScreen('exhibitions')">
						<div class="title">
							<h2 class="exhibitions">{{ sectionExhibitions ? sectionExhibitions.locale.title :
								$t('temporary_exhibition') }}</h2>
							<div class="arrow exhibitions">
								<i class="mm-icon mm-icon-arrow-right big" />
							</div>
						</div>
					</div>

					<!-- Timeline -->
					<div v-if="museum.timeline" :key="route.idRoute + 3" class="option timeline"
						:style="getNavigationSectionStyles('sectionTimeline')" @click="setFullScreen('timeline')">
						<div class="title">
							<h2 class="timeline">{{ sectionTimeline ? sectionTimeline.locale.title : $t('time_line') }}</h2>
							<div class="arrow timeline">
								<i class="mm-icon mm-icon-arrow-right big" />
							</div>
						</div>
					</div>
				</template>


			</template>
			<template v-else-if="museum.code !== 'CACSA'">
				<!-- Exhibitions -->
				<div v-if="exhibitions && exhibitions.length && sectionExhibitions && sectionExhibitions.enableSection"
					class="option exhibitions" :style="getNavigationSectionStyles('sectionExhibitions')"
					@click="setFullScreen('exhibitions')">
					<div class="title">
						<h2 class="exhibitions">{{ sectionExhibitions ? sectionExhibitions.locale.title : $t('temporary_exhibition') }}</h2>
						<div class="arrow exhibitions">
							<i class="mm-icon mm-icon-arrow-right big"/>
						</div>
					</div>
				</div>

				<!-- Routes -->
				<div v-if="routes && routes.length && sectionRoutes && sectionRoutes.enableSection" class="option routes"
					:style="getNavigationSectionStyles('sectionRoutes')" @click="setFullScreen('routes')">
					<div class="title">
						<h2 class="routes">{{ sectionRoutes ? sectionRoutes.locale.title : $t('selected_routes') }}</h2>
						<div class="arrow routes">
							<i class="mm-icon mm-icon-arrow-right big"/>
						</div>
					</div>
				</div>

				<!-- Timeline -->
				<div v-if="museum.timeline" class="option timeline"
				     :style="getNavigationSectionStyles('sectionTimeline')" @click="setFullScreen('timeline')">
					<div class="title">
						<h2 class="timeline">{{ sectionTimeline ? sectionTimeline.locale.title : $t('time_line') }}</h2>
						<div class="arrow timeline">
							<i class="mm-icon mm-icon-arrow-right big"/>
						</div>
					</div>
				</div>

				<!-- Highlighted routes -->
				<div v-for="route in highlightedRoutes" :key="route.idRoute"
				     class="option highlighted-route"
				     :style="getHighlightedRouteSectionStyles(route)"
				     @click="goToRoute(route)">
					<div class="title">
						<h2 class="highlighted-route">{{ route.locale.name }}</h2>
						<div class="arrow highlighted-route">
							<i class="mm-icon mm-icon-arrow-right big"/>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<!-- Routes -->
				<div v-if="routes && routes.length && sectionRoutes && sectionRoutes.enableSection" class="option routes"
					:style="getNavigationSectionStyles('sectionRoutes')" @click="setFullScreen('routes')">
					<div class="title">
						<h2 class="routes">{{ sectionRoutes ? sectionRoutes.locale.title : $t('selected_routes') }}</h2>
						<div class="arrow routes">
							<i class="mm-icon mm-icon-arrow-right big"/>
						</div>
					</div>
				</div>

				<!-- Highlighted routes -->
				<div v-for="route in highlightedRoutes" :key="route.idRoute"
				     class="option highlighted-route"
				     :style="getHighlightedRouteSectionStyles(route)"
				     @click="goToRoute(route)">
					<div class="title">
						<h2 class="highlighted-route">{{ route.locale.name }}</h2>
						<div class="arrow highlighted-route">
							<i class="mm-icon mm-icon-arrow-right big"/>
						</div>
					</div>
				</div>

				<!-- Exhibitions -->
				<div v-if="exhibitions && exhibitions.length && sectionExhibitions && sectionExhibitions.enableSection" class="option exhibitions"
				     :style="getNavigationSectionStyles('sectionExhibitions')" @click="setFullScreen('exhibitions')">
					<div class="title">
						<h2 class="exhibitions">{{ sectionExhibitions ? sectionExhibitions.locale.title : $t('temporary_exhibition') }}</h2>
						<div class="arrow exhibitions">
							<i class="mm-icon mm-icon-arrow-right big"/>
						</div>
					</div>
				</div>

				<!-- Timeline -->
				<div v-if="museum.timeline" class="option timeline"
				     :style="getNavigationSectionStyles('sectionTimeline')" @click="setFullScreen('timeline')">
					<div class="title">
						<h2 class="timeline">{{ sectionTimeline ? sectionTimeline.locale.title : $t('time_line') }}</h2>
						<div class="arrow timeline">
							<i class="mm-icon mm-icon-arrow-right big"/>
						</div>
					</div>
				</div>
			</template>

			<!-- Highlighted side menu options -->
			<template>
			<div v-for="sideMenuOption in sideMenuHighlightedOptions" :key="sideMenuOption" class="option sideMenu" :class="sideMenuOption"
			     :style="getNavigationSectionStyles('sectionSideMenu', sideMenuOption)" @click="goToSideMenuOption(sideMenuOption)">
					<div class="title">
					<h2 class="sideMenu">{{ sideMenu ? sideMenu.locale[sideMenuOption + 'Text'] : $t(sideMenuOption) }}</h2>
						<div class="arrow sideMenu">
						<i class="mm-icon mm-icon-arrow-right big"/>
						</div>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import SideMenu from "@/components/SideMenu";
import {setFullScreen} from "@/store/helpers";
import backgroundImage from '@/assets/home.jpg';

export default {
	name: "Options",

	components: {
		SideMenu,
		Loading
	},

	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			showMenu: false,
			sideMenuOptions: [
				'start',
				'inAMinute',
				'outstandingArtworks',
				'language',
				'exploreMuseum',
				'guide',
				'study',
				'downloads',
			],
			sectionSponsors: null,
			sectionKeyboard: {
				arrowColor: "#FFFFFF",
				lineColor: "#F06140",
				locale: {
					title: this.$i18n.t('keyboard_view')
				},
				fontColor: '#FFFFFF',
				fontSize: 32,
				enableSection: true,
				default: true,
			},
			sectionExhibitions: {
				arrowColor: "#FFFFFF",
				lineColor: "#17779B",
				locale: {
					title: this.$i18n.t('temporary_exhibition')
				},
				fontColor: '#FFFFFF',
				fontSize: 32,
				enableSection: true,
				default: true,
			},
			sectionRoutes: {
				arrowColor: "#FFFFFF",
				lineColor: "#D1B490",
				locale: {
					title: this.$i18n.t('selected_routes')
				},
				fontColor: '#FFFFFF',
				fontSize: 32,
				enableSection: true,
				default: true,
			},
			sectionTimeline: {
				arrowColor: "#FFFFFF",
				lineColor: "#94AF49",
				locale: {
					title: this.$i18n.t('time_line')
				},
				fontColor: '#FFFFFF',
				fontSize: 32,
				enableSection: true,
				default: true,
			},
			sectionSideMenu: {
				arrowColor: "#FFFFFF",
				lineColor: "#39393a",
				title: null,
				fontSize: 32,
				fontColor: '#FFFFFF',
				active: false,
				default: true,
			},
		};
	},

	computed: {
		...mapState({
			museum: state => state.museum.museum,
			highlightedRoutes: state => state.route.highlightedRoutes,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			navigationSections: state => state.navigationSection.navigationSections,
			navigationSectionsError: state => state.navigationSection.navigationSectionsError
		}),

		pageTitle() {
			let locationName = this.$i18n.t('start');

			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";

			return locationName + ' | ' + museumName;
		},

		cssVars () {
			let keyboardImage = null;
			let exhibitionsImage = null;
			let routesImage = null;
			let timelineImage = null;
			let sponsorsImage = this.sectionSponsors ? this.sectionSponsors['mainImageUrl'] : '';

			if(this.museum) {
				keyboardImage = this.museum['tourImageUrl'];

				if(this.museum.routes != null) {
					for(const route of this.museum.routes) {
						if(route['isExhibition']) {
							exhibitionsImage = route['mainImageUrl'];
							break;
						}
					}

					if(this.museum.routes?.length
						&& this.museum.routes[0]) {
						routesImage = this.museum.routes[0]['mainImageUrl'];
					}
				}

				if(this.museum.hasOwnProperty('timeline')
					&& this.museum.timeline != null) {
					timelineImage = this.museum.timeline['mainImageUrl'];
				}

				if(this.museum.hasOwnProperty('artworks')
					&& this.museum.artworks[0]) {
					if(routesImage == null) {
						routesImage = this.museum.artworks[0]['mainImageUrl'];
					}
					if(exhibitionsImage == null) {
						exhibitionsImage = this.museum.artworks[0]['mainImageUrl'];
					}
					if(timelineImage == null) {
						timelineImage = this.museum.artworks[0]['mainImageUrl'];
					}
				}
			}

			return {
				'--bg-image-sectionKeyboard': 'url(' + (keyboardImage ? keyboardImage : '') + ')',
				'--bg-image-sectionExhibitions': 'url(' + (exhibitionsImage ? exhibitionsImage : '') + ')',
				'--bg-image-sectionRoutes': 'url(' + (routesImage ? routesImage : '') + ')',
				'--bg-image-sectionTimeline': 'url(' + (timelineImage ? timelineImage : '') + ')',
				'--bg-image-sectionSponsors': 'url(' + sponsorsImage + ')',
			};
		},

		routes () {
			if(this.museum) {
				return this.museum.routes;
			} else {
				return [];
			}
		},

		exhibitions () {
			if(this.routes) {
				return this.routes.filter((route) => route.isExhibition === true);
			} else {
				return [];
			}
		},

		sideMenu () {
			return this.museum.sideMenuCustomization;
		},

		sideMenuHighlightedOptions () {
			let highlightedOptions = [];
			for(const option of this.sideMenuOptions) {
				if(this.sideMenu && this.sideMenu[option + 'Highlighted']) {
					if(!this.sideMenu[option + 'TopBar']) {
						highlightedOptions.push(option);
					}
				}
			}
			return highlightedOptions;
		},
		fontMalaga() {
			let fontFamily = "'Montserrat-Light', sans-serif";
			if (this.museum && this.museum.code) {
			const isMuseumPicasso = this.museum.code === 'MPICASSOM';
			fontFamily = isMuseumPicasso ? "'Avenir-pro', sans-serif" : "'Montserrat-Light', sans-serif";
			
			}
			return { fontFamily };
  }
	},

	created() {
		debug.log("[Options] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: 'Options', params: { idMuseum: this.encrypted } });

		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];

			const self = this;
			this.loadMuseum(this.idMuseum)
				.then(() => {
					self.getHighlightedRoutes();
					self.getNavigationSections();

					let foundSection = self.navigationSections.find((s) => s.sectionType === 'KEYBOARD');
					self.sectionKeyboard = foundSection ? foundSection : self.sectionKeyboard;
					foundSection = self.navigationSections.find((s) => s.sectionType === 'EXHIBITIONS');
					self.sectionExhibitions = foundSection ? foundSection : self.sectionExhibitions;
					foundSection = self.navigationSections.find((s) => s.sectionType === 'ROUTES');
					self.sectionRoutes = foundSection ? foundSection : self.sectionRoutes;
					foundSection = self.navigationSections.find((s) => s.sectionType === 'TIMELINE');
					self.sectionTimeline = foundSection ? foundSection : self.sectionTimeline;
					foundSection = self.navigationSections.find((s) => s.sectionType === 'SIDE_MENU');
					self.sectionSideMenu = foundSection ? foundSection : self.sectionSideMenu;
					self.sectionSponsors = self.navigationSections.find((s) => s.sectionType === 'SPONSORS');
				})
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},

	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('navigationSection', ['getNavigationSections']),
		...mapActions('route', ['getHighlightedRoutes']),

		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},

		goToSideMenuOption(to) {
			setFullScreen();
			let viewName = 'Options';
			switch(to) {
				case 'start':
					viewName = 'Options';
					break;
				case 'inAMinute':
					viewName = 'OneMinuteList';
					break;
				case 'outstandingArtworks':
					viewName = 'OutstandingArtworks';
					break;
				case 'language':
					viewName = 'Languages';
					break;
				case 'exploreMuseum':
					viewName = 'InfoSectionList';
					break;
				case 'guide':
					viewName = 'GuidedVisit';
					break;
				case 'study':
					viewName = 'StudyQuestion';
					break;
				case 'downloads':
					viewName = 'Downloads';
					break;
			}
			this.$router.push({ name: viewName, params: {idMuseum: this.encrypted} });
		},

		goToSponsors(resources) {
			let resource = {};
			for(let navigationSectionResource of resources) {
				if(navigationSectionResource.hasOwnProperty('localizations') && navigationSectionResource.localizations[0]) {
					let cleanLocalization = navigationSectionResource.localizations[0].languageCode.replace(/[|&;$%@"<>_\-()+,]/g, "").substr(0, 2);
					let cleanLocale =  this.getLocaleFromStorageOrI18n();
					if(cleanLocalization === cleanLocale) {
						resource = navigationSectionResource;
					}
				} else {
					resource = navigationSectionResource;
				}
			}
			setFullScreen();
			this.$router.push({name: 'Sponsors', params: {idResource: resource.idResource},});
		},

		goToRoute(route) {
			setFullScreen();
			let museumRoute = this.museum.routes.find((museumRoute) => museumRoute.idRoute === route.idRoute);
			if(museumRoute) {
				if(museumRoute.withPresentation) {
					this.$router.push({
						name: (route.isExhibition ? "ExhibitionList" : "RouteList"),
						params: { idMuseum: this.encrypted },
						query: { from: 'Options', idRoute: route.idRoute }
					});
					return;
				}
			}
			this.$router.push({
				name: (route.isExhibition ? "Exhibition" : "Route"),
				params: { idMuseum: this.encrypted, idRoute: route.idRoute },
				query: { from: 'Options' }
			});
		},

		getNavigationSectionStyles (sectionName, option = null) {
			let styles = {};
			let section = this[sectionName];
			if(section) {
				if (option) {
					let url = this.sideMenu[option + 'BackgroundImageUrl'] || backgroundImage;
					styles['--bg-image-' + sectionName + '-' + option] = 'url(' + url + ')';
				} else if (!section.default && section.active && section.mainImageUrl) {
					styles['--bg-image-' + sectionName] = 'url(' + section.mainImageUrl + ')';
				}

				let colors = {
					sectionKeyboard: '#f06140',
					sectionExhibitions: '#17779b',
					sectionRoutes: '#d1b490',
					sectionTimeline: '#94af49',
					sectionSponsors: '#72c4d0',
					sectionSideMenu: '#39393a',
				};
				styles['--line-color-' + sectionName] = (section.active && section.lineColor) ? section.lineColor : colors[sectionName];

				styles['--font-size-' + sectionName] = (section.active && section.fontSize) ? section.fontSize + 'px' : '32px';
				styles['--font-color-' + sectionName] = (section.active && section.fontColor) ? section.fontColor : '#ffffff';
				styles['--arrow-color-' + sectionName] = (section.active && section.arrowColor) ? section.arrowColor : '#ffffff';
			}

			return styles;
		},

		getHighlightedRouteSectionStyles (route) {
			let styles = {};
			if(route.mainImageUrl) {
				styles['--bg-image'] = 'url(' + this.getImageUrl(route.mainImageUrl) + ')';
			}

			if(route.isExhibition && this.sectionExhibitions?.active && this.sectionExhibitions.lineColor) {
				styles['--line-color'] = this.sectionExhibitions.lineColor;
				styles['--font-color'] = this.sectionExhibitions.fontColor;
				styles['--arrow-color'] = this.sectionExhibitions.arrowColor;
				styles['--font-size'] = this.sectionExhibitions.fontSize + 'px';
			} else if(!route.isExhibition && this.sectionRoutes?.active && this.sectionRoutes.lineColor) {
				styles['--line-color'] = this.sectionRoutes.lineColor;
				styles['--font-color'] = this.sectionRoutes.fontColor;
				styles['--arrow-color'] = this.sectionRoutes.arrowColor;
				styles['--font-size'] = this.sectionRoutes.fontSize + 'px';
			} else {
				styles['--line-color'] = route.isExhibition ? '#17779b' : '#d1b490';
				styles['--font-color'] = '#ffffff';
				styles['--arrow-color'] = '#ffffff';
				styles['--font-size'] = '24px';
			}

			return styles;
		},

    getImageUrl(url) {
      if (url instanceof Blob){
        return URL.createObjectURL(url);
      }

      return url;
    },
		getLocaleFromStorageOrI18n(){
			let locale = this.getLanguageStoragev2();
			if (locale){
				return locale.replace(/[|&;$%@"<>_\-()+,]/g, "").substr(0, 2);
			}

			return this.$i18n.locale.replace(/[|&;$%@"<>_\-()+,]/g, "").substr(0, 2);
		},
		getLanguageStoragev2(){
			const language = JSON.parse(localStorage.getItem('languagesV2'));
			const visitv2 = JSON.parse(localStorage.getItem('visitv2'));
			const response = language?.find(e => e.id === visitv2?.idLanguage)?.code ;
			return response || null;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../theme/colors.scss';

.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;

	nav {
		height: 75px;
		min-height: 75px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #cfcfd0;
		background-color: white;

		.logo {
			padding: 5px 20px;
			height: 100%;
			display: flex;
			align-items: center;

			.logo-image {
				max-height: 100%;
				max-width: 100%;
			}
		}

		.menu {
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: 1px solid #cfcfd0;
			padding: 5px 20px;
			width: 80px;
			text-decoration: none;

			&:not(:last-child) {
				margin-left: auto;
			}

			.menu-icon {
				color: rgb(51, 51, 51);
				text-decoration: none;
				font-size: 40px;
				width: 40px;
				height: auto;
			}
		}
	}

	.options {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow-y: auto;

		.option {
			text-decoration: none;
			min-height: 24%;
			flex: 1;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			width: 100%;

			.title {
				height: 100%;
				width: 100%;
				display: flex;
				justify-content: space-between;
				overflow: hidden;

				h2 {
					padding: 0 10px 5px 10px;
					flex: 3;
					align-self: flex-end;
					word-break: break-word;
					color: white;
					font-family: 'Montserrat-Light', sans-serif;
					background: linear-gradient(transparent 20%, rgba(0, 0, 0, 0.7));
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;

					&.keyboard {
						color: var(--font-color-sectionKeyboard);
						font-size: var(--font-size-sectionKeyboard);
					}

					&.exhibitions {
						color: var(--font-color-sectionExhibitions);
						font-size: var(--font-size-sectionExhibitions);
					}

					&.routes {
						color: var(--font-color-sectionRoutes);
						font-size: var(--font-size-sectionRoutes);
					}

					&.timeline {
						color: var(--font-color-sectionTimeline);
						font-size: var(--font-size-sectionTimeline);
					}

					&.sponsors {
						color: var(--font-color-sectionSponsors);
						font-size: var(--font-size-sectionSponsors);
					}

					&.sideMenu {
						color: var(--font-color-sectionSideMenu);
						font-size: var(--font-size-sectionSideMenu);
					}

					&.highlighted-route {
						color: var(--font-color);
						font-size: var(--font-size);
					}
				}

				.arrow {
					width: 40px;
					height: 40px;
					font-size: 25px;
					align-self: flex-end;
					padding: 5px 16px;
					display: flex;
					justify-content: center;
					align-items: center;

					.mm-icon {
						font-size: 24px;
						margin-bottom: -7px;
					}

					&.keyboard {
						color: var(--arrow-color-sectionKeyboard);
						background-color: var(--line-color-sectionKeyboard);
					}

					&.exhibitions {
						color: var(--arrow-color-sectionExhibitions);
						background-color: var(--line-color-sectionExhibitions);
					}

					&.routes {
						color: var(--arrow-color-sectionRoutes);
						background-color: var(--line-color-sectionRoutes);
					}

					&.timeline {
						color: var(--arrow-color-sectionTimeline);
						background-color: var(--line-color-sectionTimeline);
					}

					&.sponsors {
						color: var(--arrow-color-sectionSponsors);
						background-color: var(--line-color-sectionSponsors);
					}

					&.sideMenu {
						color: var(--arrow-color-sectionSideMenu);
						background-color: var(--line-color-sectionSideMenu);
					}

					&.highlighted-route {
						color: var(--arrow-color);
						background-color: var(--line-color);
					}
				}
			}

			&.keyboard {
				background: #d7d7d7 var(--bg-image-sectionKeyboard) no-repeat center center;
				background-size: cover;
				border-bottom: 4px solid var(--line-color-sectionKeyboard);
			}

			&.exhibitions {
				background: #d7d7d7 var(--bg-image-sectionExhibitions) no-repeat center center;
				background-size: cover;
				border-bottom: 4px solid var(--line-color-sectionExhibitions);
			}

			&.routes {
				background: #d7d7d7 var(--bg-image-sectionRoutes) no-repeat center center;
				background-size: cover;
				border-bottom: 4px solid var(--line-color-sectionRoutes);
			}

			&.timeline {
				background: #d7d7d7 var(--bg-image-sectionTimeline) no-repeat center center;
				background-size: cover;
				border-bottom: 4px solid var(--line-color-sectionTimeline);
			}

			&.sponsors {
				background: #d7d7d7 var(--bg-image-sectionSponsors) no-repeat center center;
				background-size: cover;
				border-bottom: 4px solid var(--line-color-sectionSponsors);
			}

			&.sideMenu {
				border-bottom: 4px solid var(--line-color-sectionSideMenu);

				&.start {
					background: #d7d7d7 var(--bg-image-sectionSideMenu-start) no-repeat center center;
					background-size: cover;
				}

				&.inAMinute {
					background: #d7d7d7 var(--bg-image-sectionSideMenu-inAMinute) no-repeat center center;
					background-size: cover;
				}

				&.outstandingArtworks {
					background: #d7d7d7 var(--bg-image-sectionSideMenu-outstandingArtworks) no-repeat center center;
					background-size: cover;
				}

				&.language {
					background: #d7d7d7 var(--bg-image-sectionSideMenu-language) no-repeat center center;
					background-size: cover;
				}

				&.exploreMuseum {
					background: #d7d7d7 var(--bg-image-sectionSideMenu-exploreMuseum) no-repeat center center;
					background-size: cover;
				}

				&.guide {
					background: #d7d7d7 var(--bg-image-sectionSideMenu-guide) no-repeat center center;
					background-size: cover;
				}

				&.survey {
					background: #d7d7d7 var(--bg-image-sectionSideMenu-survey) no-repeat center center;
					background-size: cover;
				}

				&.downloads {
					background: #d7d7d7 var(--bg-image-sectionSideMenu-downloads) no-repeat center center;
					background-size: cover;
				}
			}

			&.highlighted-route {
				background: #d7d7d7 var(--bg-image) no-repeat center center;
				background-size: cover;
				border-bottom: 4px solid var(--line-color);
			}
		}
	}
}
</style>
